import React from "react"

const LogoEurope = () => (
  <svg version="1.1" x="0px" y="0px" width="289.41px" height="86.21px" viewBox="0 0 289.41 86.21" style={{enableBackground: 'new 0 0 289.41 86.21'}}>
    <path fill="currentColor" d="M46.01,36.98c9.54-1.34,16.59,5.34,16.57,14.92c-0.02,9.67-7.19,18.45-16.73,19.79
      c-9.54,1.34-16.59-5.34-16.57-14.92C29.3,47.1,36.47,38.32,46.01,36.98z M53.85,53.32l0-0.1c0.01-4.82-3.21-8.56-7.94-7.89
      c-4.68,0.66-7.83,5.2-7.83,10.01l0,0.09c-0.01,4.82,3.21,8.56,7.89,7.9C50.69,62.68,53.84,58.14,53.85,53.32 M0,60.89
      c-0.02,9.96,7.08,16.26,15.92,15.02c6.84-0.96,10.78-4.7,13.7-9.45l-6.44-4.05c-1.86,2.69-3.76,4.57-6.98,5.03
      c-4.33,0.61-7.37-2.87-7.36-7.97c0.01-4.86,3.06-9.25,7.39-9.86c2.96-0.42,4.99,0.83,6.75,2.96l6.46-6.29
      c-2.73-3.71-6.79-6-13.1-5.12C6.97,42.47,0.02,51.07,0,60.89z M88.81,31.63l-7.4,13.96L74.06,33.7L65,34.98l-0.06,33.37l8.39-1.18
      l0.04-19.07l7.83,11.86l0.18-0.02l7.93-14.18l-0.04,19.17l8.52-1.2l0.06-33.37L88.81,31.63z M114.52,28.02
      c7.82-1.1,12.85,2.53,12.83,9.93c-0.01,7.96-5.63,12.89-13.32,13.97l-4.24,0.6l-0.02,9.54l-8.57,1.2l0.06-33.37L114.52,28.02z
      M118.78,39.67l0-0.09c0.01-2.85-1.84-4.12-4.93-3.69l-4.02,0.57l-0.02,8.82l4.06-0.57C116.92,44.27,118.77,42.3,118.78,39.67
      M149.95,44.78l7.36,10.59l-9.89,1.39l-6.21-9.23l-3.75,0.53l-0.02,10.11l-8.57,1.2l0.06-33.37l14.62-2.05
      c4.73-0.66,7.99,0.22,10.06,2.21c1.81,1.66,2.73,4.1,2.73,7.39C156.32,38.73,153.79,42.47,149.95,44.78z M147.71,35.41l0-0.09
      c0-2.62-1.76-3.7-4.63-3.3l-5.61,0.79l-0.01,8.01l5.65-0.79C146.02,39.61,147.71,37.85,147.71,35.41 M174.24,18.96
      c9.54-1.34,16.59,5.34,16.57,14.92c-0.02,9.67-7.19,18.45-16.73,19.79c-9.54,1.34-16.59-5.34-16.57-14.92
      C157.53,29.08,164.69,20.3,174.24,18.96z M182.07,35.3l0-0.1c0.01-4.82-3.21-8.56-7.94-7.89c-4.68,0.66-7.83,5.2-7.83,10.01l0,0.09
      c-0.01,4.82,3.21,8.56,7.89,7.9C178.91,44.66,182.06,40.12,182.07,35.3 M217.03,13.61l-7.39,13.96l-7.35-11.89l-9.06,1.27
      l-0.06,33.37l8.39-1.18l0.04-19.07l7.83,11.86l0.18-0.03l7.92-14.17l-0.04,19.17l8.53-1.2l0.06-33.37L217.03,13.61z M238.22,44
      l0.06-33.37l-8.61,1.21l-0.06,33.37L238.22,44z M240.96,20.45c-0.01,6.86,5.06,8.16,11.64,8.85c4.2,0.46,5.2,1.08,5.2,2.56
      c0,1.43-1.24,2.46-3.54,2.78c-3.57,0.5-6.97-0.4-10.01-2.59l-4.79,6.82c3.79,3.14,9,4.27,14.48,3.5c7.51-1.05,12.5-5.81,12.52-12.72
      c0.01-6.24-4.4-8.1-11.51-8.77c-4.28-0.44-5.34-0.96-5.34-2.59c0-1.19,1.02-2.29,3.1-2.58c2.74-0.39,5.83,0.32,8.65,2.07l4.3-7.13
      c-3.35-2.43-7.46-3.39-12.71-2.65C245.53,9.04,240.97,14.16,240.96,20.45z M235.21,0l-4.61,9.47l5.92-0.83l6.51-7.01L235.21,0z
      M130.88,64.84c4.74-0.67,7.79,1.54,7.78,6.02c-0.01,4.83-3.42,7.83-8.08,8.48l-2.57,0.36L128,85.48l-5.2,0.73l0.04-20.24
      L130.88,64.84z M133.46,71.91l0-0.06c0-1.74-1.12-2.5-2.99-2.24l-2.44,0.34l-0.01,5.35l2.46-0.35
      C132.33,74.7,133.46,73.49,133.46,71.91 M139.61,63.61l-0.04,20.24l15.22-2.14l0.01-4.77l-10.07,1.42l0.01-3.21l9-1.26l0.01-4.43
      l-9,1.26l0.01-3.06l9.94-1.4l0.01-4.77L139.61,63.61z M168.98,72.67l4.46,6.43l-6,0.84l-3.77-5.59l-2.28,0.32l-0.01,6.13l-5.19,0.73
      l0.04-20.24l8.87-1.25c2.87-0.4,4.85,0.13,6.1,1.34c1.1,1,1.66,2.48,1.65,4.48C172.85,69,171.31,71.27,168.98,72.67z M167.63,66.99
      l0-0.06c0-1.59-1.07-2.25-2.81-2.01l-3.41,0.48l-0.01,4.86l3.43-0.48C166.6,69.53,167.63,68.46,167.63,66.99 M180.4,57.88
      l-0.04,20.24l15.21-2.14l0.01-4.77l-10.07,1.42l0.01-3.21l9-1.27l0.01-4.43l-9,1.27l0.01-3.06l9.94-1.4l0.01-4.77L180.4,57.88z
      M208.96,53.87l-0.02,11.39c-0.01,2.84-1.37,4.36-3.46,4.65c-2.09,0.29-3.45-0.9-3.45-3.82l0.02-11.25l-5.28,0.74l-0.02,11.37
      c-0.01,6.33,3.33,8.78,8.66,8.03c5.33-0.75,8.79-4.1,8.8-10.63l0.02-11.22L208.96,53.87z M228.81,64.26l4.46,6.43l-6,0.84l-3.76-5.6
      l-2.28,0.32l-0.01,6.13l-5.19,0.73l0.04-20.24l8.87-1.25c2.87-0.4,4.85,0.13,6.1,1.34c1.1,1,1.66,2.48,1.65,4.48
      C232.68,60.59,231.14,62.86,228.81,64.26z M227.46,58.58l0-0.06c0-1.59-1.06-2.25-2.81-2.01l-3.4,0.48l-0.01,4.86l3.43-0.48
      C226.44,61.12,227.45,60.05,227.46,58.58 M243.55,48.6c5.79-0.81,10.06,3.24,10.05,9.05c-0.01,5.87-4.36,11.19-10.15,12.01
      c-5.79,0.81-10.06-3.24-10.05-9.05C233.41,54.73,237.76,49.41,243.55,48.6z M248.3,58.51l0-0.06c0.01-2.92-1.94-5.19-4.81-4.79
      c-2.84,0.4-4.75,3.15-4.75,6.07l0,0.06c-0.01,2.92,1.94,5.19,4.79,4.79C246.39,64.19,248.29,61.43,248.3,58.51 M281.46,43.53
      l7.95,19.26l-5.57,0.78l-1.36-3.42l-7.24,1.02l-1.34,3.8l-5.47,0.77l8.02-21.51L281.46,43.53z M276.78,56.59l4.2-0.59l-2.08-5.47
      L276.78,56.59 M263.11,46.26c4.74-0.67,7.79,1.54,7.79,6.02c-0.01,4.83-3.42,7.83-8.08,8.48l-2.57,0.36l-0.01,5.78l-5.2,0.73
      l0.04-20.24L263.11,46.26z M265.69,53.32l0-0.06c0-1.74-1.12-2.5-3-2.24l-2.43,0.34l-0.01,5.35l2.46-0.35
      C264.56,56.11,265.69,54.91,265.69,53.32"/>
  </svg>
)

export default LogoEurope